import React, {createElement} from "react";
import {withRouter} from 'react-router-dom';
import {useSelector} from 'react-redux';
import {useMediaQuery, useTheme} from '@material-ui/core';
import {DashboardMenuItem, getResources, MenuItemLink} from 'react-admin';
import LabelIcon from '@material-ui/icons/Label';
import ListIcon from '@material-ui/icons/List';
import PriceListIcon from '@material-ui/icons/Money';
import AdminUserIcon from '@material-ui/icons/SupervisorAccount';
import {capitalizeFirstLetter} from "../helpers/helpers";

const Menu = ({onMenuClick, logout}) => {
    const theme = useTheme();
    const isXSmall = useMediaQuery(theme.breakpoints.down('xs'));
    const open = useSelector(state => state.admin.ui.sidebarOpen);
    const resources = useSelector(getResources);

    function getIcon(resource) {
        return resource.icon ? createElement(resource.icon) : <ListIcon />;
    }

    function getLabel(resource) {
        return  (resource.options && resource.options.label) || capitalizeFirstLetter(resource.name.replaceAll('_', ' '));
    }

    return (
        <div style={{ paddingTop: '1em' }}>
            <DashboardMenuItem />
            {resources.filter(r => r.hasList).map(resource => (
                <MenuItemLink
                    key={resource.name}
                    to={`/${resource.name}`}
                    primaryText={getLabel(resource)}
                    leftIcon={getIcon(resource)}
                    onClick={onMenuClick}
                    sidebarIsOpen={open}
                />
            ))}
            <MenuItemLink
                to="/price_list"
                primaryText="Price list"
                leftIcon={<PriceListIcon/>}
                onClick={onMenuClick}
                sidebarIsOpen={open}
            />
            <MenuItemLink
                to="/risk-management"
                primaryText="Risk management"
                leftIcon={<LabelIcon/>}
                onClick={onMenuClick}
                sidebarIsOpen={open}
            />
            <MenuItemLink
                to="/queue"
                primaryText="Queue"
                leftIcon={<LabelIcon/>}
                onClick={onMenuClick}
                sidebarIsOpen={open}
            />
            <MenuItemLink
                to="/quotes"
                primaryText="Quotes"
                leftIcon={<LabelIcon/>}
                onClick={onMenuClick}
                sidebarIsOpen={open}
            />
            <MenuItemLink
                to="/billing"
                primaryText="Billing"
                leftIcon={<LabelIcon/>}
                onClick={onMenuClick}
                sidebarIsOpen={open}
            />
            <MenuItemLink
                to="/received-invoices"
                primaryText="Received bills"
                leftIcon={<LabelIcon/>}
                onClick={onMenuClick}
                sidebarIsOpen={open}
            />
            <MenuItemLink
                to="/promissory-notes"
                primaryText="Promissory notes"
                leftIcon={<LabelIcon/>}
                onClick={onMenuClick}
                sidebarIsOpen={open}
            />
            <MenuItemLink
                to="/invoices"
                primaryText="Invoices"
                leftIcon={<LabelIcon/>}
                onClick={onMenuClick}
                sidebarIsOpen={open}
            />
            <MenuItemLink
                to="/debtor-payments"
                primaryText="Debtor payments"
                leftIcon={<LabelIcon/>}
                onClick={onMenuClick}
                sidebarIsOpen={open}
            />
            <MenuItemLink
                to="/logs"
                primaryText="Logs"
                leftIcon={<LabelIcon/>}
                onClick={onMenuClick}
                sidebarIsOpen={open}
            />
            <MenuItemLink
                to="/organizations"
                primaryText="Clients"
                leftIcon={<LabelIcon/>}
                onClick={onMenuClick}
                sidebarIsOpen={open}
            />
            <MenuItemLink
                to="/partners"
                primaryText="Partners"
                leftIcon={<LabelIcon/>}
                onClick={onMenuClick}
                sidebarIsOpen={open}
            />
            <MenuItemLink
                to="/shared-users"
                primaryText="Shared users"
                leftIcon={<LabelIcon/>}
                onClick={onMenuClick}
                sidebarIsOpen={open}
            />
            <MenuItemLink
                to="/admins"
                primaryText="Admins"
                leftIcon={<AdminUserIcon/>}
                onClick={onMenuClick}
                sidebarIsOpen={open}
            />
            <MenuItemLink
                to="/admin-actions"
                primaryText="Admin Actions"
                leftIcon={<AdminUserIcon/>}
                onClick={onMenuClick}
                sidebarIsOpen={open}
            />
            {isXSmall && logout}
        </div>
    );
}

export default withRouter(Menu);
