import React, {useEffect, useState} from 'react';
import {classNames, sharedAccessTokenKey, sharedFetch,} from "../helpers/helpers";
import {
    CheckIcon,
    ChevronDownIcon,
    ChevronUpIcon
} from "@heroicons/react/solid";
import Spinner from "../components/spinner";
import {fireTimestampToDate, fireTimestampToDateString, toCurrency} from "../utils";

const now = new Date().getTime()
const defaultColumns = [
    {
        v: d => d.promissoryNote?.id ? (
            <a
                href={"#/promissory-notes/" + d.promissoryNote.id}
                className="underline"
            >{d.promissoryNote?.number}</a>
        ) : (d.promissoryNote?.number || "-"), label: "Note #", sort: "promissoryNote.sortingNumber"
    },
    {
        v: d => d.issuer?.oid ? (
            <a
                href={"#/investmentCompanies/" + d.issuer?.oid}
                className="underline"
            >{d.issuer?.shortName || d.issuer?.name}</a>
        ) : ((d.issuer?.shortName || d.issuer?.name) || "-"), label: "Supplier", sort: "issuer.shortName"
    },
    {
        v: d => d.promissoryNote?.id && d.id ? (
            <a
                href={"#/promissory-notes/" + d.promissoryNote.id + "/invoices/" + d.id}
                className="underline"
            >{d.number}</a>
        ) : (d.number || "-"), label: "Invoice #", sort: "number"
    },
    {v: d => fireTimestampToDateString(d.date), label: "Date", sort: "date", direction: "asc"},
    {v: d => fireTimestampToDateString(d.dueDate), label: "Due Date", sort: "dueDate"},
    {v: d => fireTimestampToDateString(d.extendedPaymentDate), label: "Extension", sort: "extendedPaymentDate"},
    {v: d => fireTimestampToDateString(d.maxExtensionDate), label: "MEP Date", sort: "maxExtensionDate"},
    {v: d => d.amount?.formatted, label: "Amount", sort: "amount.value"},
    {v: d => fireTimestampToDateString(d.repaidDate), label: "Paid Date", sort: "repaidDate"},
    {
        v: d => Math.ceil((fireTimestampToDate(d.maxExtensionDate).getTime() - now) / (1000 * 60 * 60 * 24)),
        label: "MEP Due",
        sort: "maxExtensionDate"
    },
]
const limit = 20,
    defaultOrderBy = "date",
    defaultOrderByDirection = "asc"

function getNextFourMonths() {
    const monthNames = [
        "January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"
    ];

    const currentMonth = new Date().getMonth();
    const monthsArray = [];

    for (let i = 0; i < 4; i++) {
        monthsArray.push(monthNames[(currentMonth + i) % 12]);
    }

    return monthsArray;
}

const months = getNextFourMonths()

export default function RiskManagementEntry({match}) {
    const {id} = match.params ? match.params : {};
    const [columns, setColumns] = useState(defaultColumns)
    const [orderBy, setOrderBy] = useState(defaultOrderBy)
    const [orderByDirection, setOrderByDirection] = useState(defaultOrderByDirection)
    const [isLoading, setIsLoading] = useState(false); // entry editing
    const [serverData, setServerData] = useState(null)
    const [offset, setOffset] = useState(0)
    const [result, setResult] = useState(null)
    const [isStatusFilterOn, setIsStatusFilterOn] = useState(false)

    useEffect(() => {
        id && fetch()
    }, [id]);

    useEffect(() => {
        fetchInvoices()
    }, [offset, orderBy, orderByDirection, isStatusFilterOn]);

    function fetch() {
        setIsLoading(true)
        sharedFetch(`admin/finance/risk-management/${id}`, {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem(sharedAccessTokenKey)
            }
        }).then(data => {
            if (data?.risk) setServerData(data)
        }).finally(() => setIsLoading(false))
        //fetchInvoices()
    }

    function fetchInvoices() {
        const queryString = `admin/finance/invoices?limit=${limit}&orderBy=${orderBy}&orderByDirection=${orderByDirection}&offset=${offset}&filter[]=${"customer.oid"}&filter[]=${"=="}&filter[]=${id}&filter[]=${"status"}&filter[]=${"=="}&filter[]=${isStatusFilterOn ? "SETTLED" : "NA"}`;
        sharedFetch(queryString, {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem(sharedAccessTokenKey)
            }
        }).then((json) => {
            console.log(json)
            setResult(json || null)
            //setFilterCount(json?.totalOverdue || 0)
        })
    }

    function nextPage(e) {
        //result?.offset + result?.count < result?.total
        if (result?.offset + result?.count < result?.total) setOffset(offset + limit)
    }

    function prevPage(e) {
        if (offset > 0) setOffset(offset - limit)
    }

    function sort(columnIndex) {
        return function onClick(e) {
            e.preventDefault()
            const col = columns[columnIndex]
            if (col?.sort) {
                switch (col.direction) {
                    case "asc":
                        col.direction = "desc"
                        break;/*
                    case "desc":
                        col.direction = null
                        break;*/
                    default:
                        col.direction = "asc"
                }
                if (col.direction) {
                    setOrderBy(col.sort)
                    setOrderByDirection(col.direction)
                } else {
                    setOrderBy(defaultOrderBy)
                    setOrderByDirection(defaultOrderByDirection)
                }
                setColumns(columns.map((it, i) => i === columnIndex ? {...col} : {...it, direction: null}))
                setOffset(0)
            }
        }
    }

    function toggleArchived(e) {
        setIsStatusFilterOn(!isStatusFilterOn)
    }

    const items = serverData?.risk ? [
        {
            label: "General",
            list: [
                {
                    label: "Limit",
                    value: toCurrency((serverData.organization?.financingLimits?.reverseFactoringLimit?.limit || 0) + (serverData.organization?.financingLimits?.factoringLimit?.limit || 0), true) + " EUR"
                },
                {label: "Outstanding", value: serverData.risk.debtorFinancingRisk?.all?.outstanding?.formatted},
                {label: "Available", value: serverData.risk.debtorFinancingRisk?.availableLimits?.total?.formatted},
            ],
        },
        {
            label: "Invoice Payment Status",
            list: [
                {label: "Extended", value: serverData.risk.debtorFinancingRisk?.all?.extended?.formatted},
                {label: "Delayed", value: serverData.risk.debtorFinancingRisk?.all?.delayed?.formatted},
                {label: "In recovery", value: serverData.risk.debtorFinancingRisk?.all?.inRecovery?.formatted},
            ],
        },
        {
            label: "MEP",
            list: [
                {label: "7 days", value: serverData.risk.debtorFinancingRisk?.projections?._7Days?.delayed?.formatted},
                {
                    label: "14 days",
                    value: serverData.risk.debtorFinancingRisk?.projections?._14Days?.delayed?.formatted
                },
                {
                    label: "30 days",
                    value: serverData.risk.debtorFinancingRisk?.projections?._30Days?.delayed?.formatted
                },
            ],
        },
        {
            label: "Due",
            list: [
                {
                    label: months[0],
                    value: serverData.risk.debtorFinancingRisk?.projections?.thisMonth?.extended?.formatted
                },
                {
                    label: months[1],
                    value: serverData.risk.debtorFinancingRisk?.projections?.nextMonth?.extended?.formatted
                },
                {
                    label: months[2],
                    value: serverData.risk.debtorFinancingRisk?.projections?.thirdMonth?.extended?.formatted
                },
                {
                    label: months[3],
                    value: serverData.risk.debtorFinancingRisk?.projections?.fourthMonth?.extended?.formatted
                },
            ],
        },
        {
            label: "Reverse factoring",
            list: [
                {
                    label: "Limit",
                    value: toCurrency((serverData.organization?.financingLimits?.reverseFactoringLimit?.limit || 0), true) + " EUR"
                },
                {label: "Outstanding", value: serverData.risk.debtorFinancingRisk?.byType?.RF?.outstanding?.formatted},
                {
                    label: "Available",
                    value: serverData.risk.debtorFinancingRisk?.availableLimits?.reverseFactoringLimit?.formatted
                },
            ],
        },
        {
            label: "Factoring",
            list: [
                {
                    label: "Limit",
                    value: toCurrency((serverData.organization?.financingLimits?.factoringLimit?.limit || 0), true) + " EUR"
                },
                {label: "Outstanding", value: serverData.risk.debtorFinancingRisk?.byType?.F?.outstanding?.formatted},
                {
                    label: "Available",
                    value: serverData.risk.debtorFinancingRisk?.availableLimits?.factoringLimit?.formatted
                },
            ],
        },
        {
            label: "Bills",
            list: [
                {label: "TTM", value: serverData.risk.debtorFinancingRisk?.billingRisk?.ttm?.formatted},
                {label: "YTD", value: serverData.risk.debtorFinancingRisk?.billingRisk?.ytd?.formatted},
                {label: "Outstanding", value: serverData.risk.debtorFinancingRisk?.billingRisk?.outstanding?.formatted},
            ],
        },
        // More items...
    ] : []

    return (
        <>
            <div className="my-8 space-y-6">
                {isLoading ? (
                    <Spinner/>
                ) : (
                    <>
                        <div className="bg-white shadow px-4 py-5 sm:rounded-lg sm:p-6">
                            <div className="space-y-8">
                                <div className="space-y-8 divide-y divide-gray-200 sm:space-y-5">
                                    <div>

                                        {serverData?.organization && (
                                            <div>
                                                <a href={"#/investmentCompanies/" + serverData?.organization?.oid}
                                                   className="text-lg leading-6 font-medium text-gray-900 hover:underline">{serverData?.organization?.shortName || serverData?.organization?.name}</a>
                                                <p className="mt-1 max-w-2xl text-sm text-gray-500">
                                                    {[serverData?.organization?.address?.address1, [serverData?.organization?.address?.postCode, serverData?.organization?.address?.city].filter(a => !!a).join(" "), serverData?.organization?.address?.country?.code].filter(a => !!a).join(", ")}
                                                </p>
                                            </div>
                                        )}

                                        <div className="mt-6 sm:mt-5">
                                            <div className="grid grid-cols-4 gap-4">
                                                {items.map(({label, list}, i) => (
                                                    <div key={label + i}
                                                         className="col-span-4 sm:col-span-2 xl:col-span-1 pt-3 pb-1 border rounded-md">
                                                        {label && (
                                                            <span
                                                                className="px-4 text-base font-semibold text-gray-900">{label}</span>
                                                        )}
                                                        {list?.map(({label, value}, i) => (
                                                            <div key={"_" + label + i} className="mt-1 px-4">
                                                                <div key={label}
                                                                     className={classNames("sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start box-border py-1", i > 0 ? "-mt-px border-t border-gray-200" : "")}>
                                                                    <span
                                                                        className="block text-sm font-medium sm:mt-px sm:pt-2 text-gray-700 py-1">{label}</span>
                                                                    <span
                                                                        className="sm:col-span-2 block text-sm sm:mt-px sm:pt-2 text-gray-700 py-1">{value}</span>
                                                                    {/*<div
                                                                        className="mt-1 sm:mt-0 sm:col-span-2 py-1 text-gray-900 text-sm">{value}</div>*/}
                                                                </div>
                                                            </div>
                                                        ))}
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <section className="bg-white shadow sm:rounded-lg">
                            <div className="px-4 py-5 sm:px-6 flex items-center">
                                <h3 className="text-lg font-medium leading-6 text-gray-900">Invoices</h3>
                                <div className="sm:px-8 inline-flex">
                                    <button
                                        type="button"
                                        onClick={toggleArchived}
                                        className={classNames(
                                            "relative h-8 inline-flex rounded-full items-center text-sm font-medium box-border border-2 bg-gray-100 text-gray-700 transition-all",
                                            isStatusFilterOn ? "pl-7 pr-3 border-current" : "px-3 border-transparent"
                                        )}>
                                        {isStatusFilterOn && (
                                            <div className="absolute left-0 top-0 bottom-0 flex items-center pl-1.5">
                                                <CheckIcon className="w-5 h-5 text-green-600"/>
                                            </div>
                                        )}
                                        Archived
                                    </button>
                                </div>
                            </div>

                            <div className="flex flex-col">
                                <div className="inline-block min-w-full align-middle">
                                    <div className="overflow-hidden border-t border-gray-200">
                                        <div className="flex flex-col">
                                            <div className="align-middle inline-block min-w-full">
                                                <div className="border-b border-gray-200">
                                                    <table className="min-w-full divide-y divide-gray-200">
                                                        <thead className="bg-gray-50">
                                                        <tr>
                                                            {columns.map((col, i) => (
                                                                <th key={col.label + i}
                                                                    className={classNames(
                                                                        "flex-1 px-4 py-3.5 text-left text-xs font-medium text-gray-500 uppercase tracking-wider",
                                                                        i === 0 ? "pl-6" : ""
                                                                    )}>
                                                                        <span onClick={sort(i)} className={classNames(
                                                                            "group inline-flex truncate items-center",
                                                                            col.sort ? "cursor-pointer" : "cursor-default"
                                                                        )}>
                                                                            <span className="py-1">{col.label}</span>
                                                                            <span className={classNames(
                                                                                col.direction ? "ml-2 flex-none rounded bg-gray-200 text-gray-900 group-hover:bg-gray-300" : "invisible ml-2 flex-none rounded text-gray-400 group-hover:visible group-focus:visible"
                                                                            )}>{col.direction === "asc" ? (
                                                                                <ChevronUpIcon className="h-5 w-5"
                                                                                               aria-hidden="true"/>
                                                                            ) : col.direction === "desc" ? (
                                                                                <ChevronDownIcon className="h-5 w-5"
                                                                                                 aria-hidden="true"/>
                                                                            ) : null}</span>
                                                    </span>
                                                                </th>
                                                            ))}
                                                        </tr>
                                                        </thead>
                                                        <tbody className="divide-y divide-gray-200 bg-white">
                                                        {/* Rows here */}
                                                        {result?.results?.map((row, i) => (
                                                            <tr key={row.id} className="">
                                                                {defaultColumns.map(({v}, i) => (
                                                                    <td key={i}
                                                                        className={classNames(
                                                                            "px-4 py-4 text-sm text-gray-900",
                                                                            i === 0 ? "pl-6" : ""
                                                                        )}>
                                                                        <span
                                                                            className="block max-w-xs truncate">{v(row)}</span>
                                                                    </td>
                                                                ))}
                                                            </tr>
                                                        ))}
                                                        </tbody>
                                                    </table>

                                                    <nav
                                                        className="bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6"
                                                        aria-label="Pagination"
                                                    >
                                                        <div className="hidden sm:block">
                                                            <p className="text-sm text-gray-700">
                                                                Showing <span
                                                                className="font-medium">{(result?.offset + 1) || "0"}</span> to <span
                                                                className="font-medium">{result?.offset + result?.count || "0"}</span> of{' '}
                                                                <span
                                                                    className="font-medium">{result?.total || "0"}</span> results
                                                            </p>
                                                        </div>
                                                        <div className="flex-1 flex justify-between sm:justify-end">
                                                            <button
                                                                type="button"
                                                                onClick={prevPage}
                                                                className={classNames(
                                                                    "relative inline-flex items-center px-4 py-2 border text-sm font-medium rounded-md",
                                                                    result?.offset ? "text-gray-700 bg-white hover:bg-gray-50 border-gray-300" : "text-gray-300 bg-white border-gray-100"
                                                                )}
                                                            >
                                                                Previous
                                                            </button>
                                                            <button
                                                                type="button"
                                                                onClick={nextPage}
                                                                className={classNames(
                                                                    "ml-3 relative inline-flex items-center px-4 py-2 border text-sm font-medium rounded-md",
                                                                    (result?.offset + result?.count < result?.total) ? "text-gray-700 bg-white hover:bg-gray-50 border-gray-300" : "text-gray-300 bg-white border-gray-100"
                                                                )}
                                                            >
                                                                Next
                                                            </button>
                                                        </div>
                                                    </nav>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>

                    </>
                )}
            </div>
        </>
    )
};
