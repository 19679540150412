import React, {Fragment, useEffect, useRef, useState} from 'react';
import {
    classNames, httpClient,
    sharedAccessTokenKey,
    sharedFetch,
} from "../helpers/helpers";
import {showNotification, useLogout} from "react-admin";
import {
    UploadIcon
} from "@heroicons/react/solid";
import {XMLParser} from "fast-xml-parser"
import DataTable from "./dataTable";
import Spinner from "../components/spinner";
import * as Config from "../config";

function findAccount(value, accNo) {
    const bankAccount = value ? value.bankAccounts.find(i => i.accountNumber?.toUpperCase() === accNo.toUpperCase()) : null
    return bankAccount ? bankAccount : null;
}

export default function TransactionBatchImport() {
    const importRef = useRef()
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [selectedItems, setSelectedItems] = useState([])
    const [result, setResult] = useState({})
    const [isProcessingImport, setIsProcessingImport] = useState(false)
    const logout = useLogout();
    const handleSearch = async (searchData) => {
        if (!searchData) return;

        try {
            let response = await httpClient(`${Config.ADMIN_API_URL}/customer/search?q=${searchData}`);
            if (response.json.length > 0) {
                return response.json[0]
            }
        } catch (e) {
            if (e.status === 401) {
                await logout();
            }
            console.log(e.body || e);
        }
        return null
    }
    const createTransaction = async (data) => {
        try {
            return await httpClient(`${Config.ADMIN_API_URL}/transactions`,
                {
                    method: "POST",
                    mode: 'cors',
                    cache: 'no-cache',
                    body: JSON.stringify(data),
                }).then(function ({json}) {
                let data = json;
                if (!data.hasOwnProperty('id')) {
                    data = {...data, id: data.Id};
                }

                return ({
                    data: data,
                });
            });
        } catch (e) {
            if (e.status === 401) {
                await logout();
            }
            console.log(e.body || e);
        }
        return null
    }

    async function onSubmit(e) {
        e.preventDefault();
        console.log("approved for import", selectedItems)
        const entriesForSubmit = []



        for (let i = 0; i < selectedItems.length; i++) {
            const item = selectedItems[i];

            /*
              id: item?.AcctSvcrRef || (Date.now() + Math.random()).toString(),
                                    debtor: {
                                        name: item?.NtryDtls?.TxDtls?.RltdPties?.Dbtr?.Nm,
                                        bankId: item?.NtryDtls?.TxDtls?.RltdAgts?.DbtrAgt?.FinInstnId?.BIC || null,
                                        bankAccountNumber: item?.NtryDtls?.TxDtls?.RltdPties?.DbtrAcct?.Id?.IBAN,
                                        address1: item?.NtryDtls?.TxDtls?.RltdPties?.Dbtr?.PstlAdr?.AdrLine?.[0] || null,
                                        address2: item?.NtryDtls?.TxDtls?.RltdPties?.Dbtr?.PstlAdr?.AdrLine?.[1] || null,
                                        countryCode: item?.NtryDtls?.TxDtls?.RltdPties?.Dbtr?.PstlAdr?.Ctry || null,
                                    },
                                    balanceIndicator: item?.CdtDbtInd === "CRDT" ? "CREDIT" : "DEBIT",
                                    creditorReference: item?.NtryDtls?.TxDtls?.RmtInf?.Strd?.CdtrRefInf?.Ref || null,
                                    amount: item?.Amt,
                                    bookingDate: new Date(item?.BookgDt?.Dt),
                                    rawTransactionDetails: JSON.stringify(item),
                                     investor: {
                                        account,
                                        id: user?.Id,
                                        name: user?.contact?.firstName + " " + user?.contact?.lastName
                                    }
                                    loading: true,
             */

            if (item?.investor?.id && item.amount && item.investor.account?.accountNumber && item.bookingDate) {
                entriesForSubmit.push({
                    amount: item.amount,
                    bankAccountNumber: item.investor.account.accountNumber,
                    date: item.bookingDate.toISOString(),
                    id: item.investor.id,
                })
            } else {
                const missing = []
                if(!item?.investor?.id) missing.push("Investor ID")
                if(!item?.amount) missing.push("Amount")
                if(!item?.investor?.account?.accountNumber) {
                    missing.push("Account Number (no match from " + JSON.stringify(item?.investor?.accounts || {}) + ")")
                }
                if(!item?.bookingDate) missing.push("Booking Date")
                alert("Error submitting. Check row #" + (i + 1) + ". Missing: " + missing.join(", "))
                return
            }
        }

        setIsSubmitting(true)
        for(let i = 0; i < entriesForSubmit.length; i++) {
            const result = await createTransaction(entriesForSubmit[i])
            console.log(result)
        }
        setIsSubmitting(false)
        goBack()
    }

    async function onImport() {
        if (isProcessingImport) return
        if (importRef?.current?.files?.length > 0) {
            console.log("Importing", importRef.current.files.length, "files")
            try {
                for (let i0 = 0; i0 < importRef.current.files.length; i0++) {
                    const importedFile = importRef.current.files[i0]
                    const results = []
                    if (importedFile) {
                        const parser = new XMLParser()
                        const buff = await importedFile.arrayBuffer()
                        const blob = new Blob([new Uint8Array(buff)], {type: importedFile.type})
                        const parsed = parser.parse(await blob.text())

                        // let's console log all debtors
                        try {
                            const entries = parsed.Document.BkToCstmrStmt.Stmt.Ntry

                            function parseSingleEntry(item) {
                                if (!item?.CdtDbtInd?.startsWith("CRDT")) return
                                results.push({
                                    /*amount: entry.Amt,
                                    creditorReference: entry.NtryDtls.TxDtls.RmtInf?.Strd?.CdtrRefInf?.Ref || null,
                                    debtor: {
                                        name: entry.NtryDtls.TxDtls.RltdPties.Dbtr.Nm,
                                        bankAccount: entry.NtryDtls.TxDtls.RltdPties.DbtrAcct.Id.IBAN
                                    },
                                    raw: entry,*/
                                    id: item?.AcctSvcrRef || (Date.now() + Math.random()).toString(),
                                    debtor: {
                                        name: item?.NtryDtls?.TxDtls?.RltdPties?.Dbtr?.Nm,
                                        bankId: item?.NtryDtls?.TxDtls?.RltdAgts?.DbtrAgt?.FinInstnId?.BIC || null,
                                        bankAccountNumber: item?.NtryDtls?.TxDtls?.RltdPties?.DbtrAcct?.Id?.IBAN,
                                        address1: item?.NtryDtls?.TxDtls?.RltdPties?.Dbtr?.PstlAdr?.AdrLine?.[0] || null,
                                        address2: item?.NtryDtls?.TxDtls?.RltdPties?.Dbtr?.PstlAdr?.AdrLine?.[1] || null,
                                        countryCode: item?.NtryDtls?.TxDtls?.RltdPties?.Dbtr?.PstlAdr?.Ctry || null,
                                    },
                                    balanceIndicator: item?.CdtDbtInd === "CRDT" ? "CREDIT" : "DEBIT",
                                    creditorReference: item?.NtryDtls?.TxDtls?.RmtInf?.Strd?.CdtrRefInf?.Ref || null,
                                    amount: item?.Amt,
                                    bookingDate: new Date(item?.BookgDt?.Dt),
                                    rawTransactionDetails: JSON.stringify(item),
                                    investor: null,
                                    loading: true,
                                })
                            }

                            if(Array.isArray(entries)) {
                                console.log("Iterating through", entries.length, "entries")
                                for (let i = 0; i < entries.length; i++) {
                                    parseSingleEntry(entries[i])
                                }
                            } else if(entries) parseSingleEntry(entries)
                        } catch (e) {
                            console.log(e)
                        }
                    }
                    setResult(r => ({results: [...(r.results || []), ...results]}))
                    setIsProcessingImport(true)

                    // search user
                    for (let j = 0; j < results.length; j++) {

                        const it = results[j],
                            id = it.id,
                            iban = it.debtor.bankAccountNumber


                        setResult(r => ({
                            results: [...(r.results || []).map(rit => rit.id === id ? ({
                                ...rit,
                                loading: true
                            }) : rit)]
                        }))

                        const user = await handleSearch(iban)
                        if (user) {
                            const account = findAccount(user, iban)
                            //console.log("USER2,", user)


                            setResult(r => ({
                                results: [...(r.results || []).map(rit => rit.id === id ? ({
                                    ...rit,
                                    investor: {
                                        accounts: user.bankAccounts,
                                        account,
                                        id: user?.Id,
                                        name: user?.contact?.firstName + " " + user?.contact?.lastName
                                    },
                                    loading: false
                                }) : rit)]
                            }))

                        } else {
                            setResult(r => ({
                                results: [...(r.results || []).map(rit => rit.id === id ? ({
                                    ...rit,
                                    investor: null,
                                    loading: false
                                }) : rit)]
                            }))
                        }


                    }

                    setIsProcessingImport(false)

                }
            } catch (e) {
                console.error(e)
                setResult({results: []})
                setIsProcessingImport(false)
                alert("There was a problem submitting your request!")
            }
            importRef.current.value = ""
        }
    }

    function goBack() {
        const link = document.createElement('a');
        link.href = "#/transactions";
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
    }

    return (<>
        <div className="my-8 space-y-6">
            <form onSubmit={onSubmit} className="bg-white shadow sm:rounded-lg">
                <div>
                    <div className="px-4 py-5 sm:px-6 flex items-center">
                        <h3 className="text-lg leading-6 font-medium text-gray-900">Import XML</h3>
                        <label
                            className="ml-4 inline-flex items-center p-1 border border-transparent rounded-full shadow-sm text-gray-500 bg-gray-200 hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
                        >
                            <input ref={importRef} type="file" className="hidden"
                                   onChange={onImport}/>
                            <UploadIcon className="h-5 w-5" aria-hidden="true"/>
                        </label>
                    </div>
                    <DataTable isLoadingData={isSubmitting}
                               setSelectedItems={setSelectedItems}
                               selectedItems={selectedItems} result={result}/>
                </div>
                <div className="flex space-x-4 justify-end bg-gray-50 px-4 py-3 text-right sm:px-6">
                    <a
                        href="#/transactions"
                        className="ml-3 bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
                    >
                        Cancel
                    </a>
                    <button
                        type="submit"
                        disabled={isSubmitting}
                        className={classNames(
                            "ml-3 inline-flex justify-center py-2 px-4 border text-sm font-medium rounded-md focus:outline-none",
                            !isSubmitting ? "text-white bg-gray-600 hover:bg-gray-700 focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 border-transparent shadow-sm" : "text-gray-300 bg-gray-50 border-gray-200 cursor-default"
                        )}
                    >
                        {isSubmitting ? "Saving..." : "Save"}
                        {isSubmitting && <Spinner button/>}
                    </button>
                </div>
            </form>
        </div>
    </>)
};
