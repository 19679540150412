import React, {Fragment, useRef, useState} from 'react';
import {
    classNames,
    sharedAccessTokenKey,
    sharedFetch,
} from "../helpers/helpers";
import {useLogout} from "react-admin";
import {
    UploadIcon
} from "@heroicons/react/solid";
import {XMLParser} from "fast-xml-parser"
import DataTable from "./dataTable";
import Spinner from "../components/spinner";

export default function DebtorPaymentsImport() {
    const importRef = useRef()
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [selectedItems, setSelectedItems] = useState([])
    const [result, setResult] = useState({})
    const logout = useLogout();

    function onSubmit(e) {
        e.preventDefault();
        console.log("approved for import", selectedItems)
        const payments = []

        for (let i = 0; i < selectedItems.length; i++) {
            const item = selectedItems[i];
            payments.push(item)
        }

        const data = {payments}

        setIsSubmitting(true)
        sharedFetch(`admin/finance/debtor-payments`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem(sharedAccessTokenKey)
            },
            body: JSON.stringify(data)
        }).then(data => {
            setIsSubmitting(false)
            if (data?.statusCode === 401) return logout()
            if (data?.message) {
                alert(data.message)
            } else if (data.ok) {
                goBack()
            } else alert("There was a problem submitting your request!")
        }).catch(e => {
            setIsSubmitting(false)
            if (e.status === 401) {
                logout();
            }
            alert("There was an error submitting your request!")
        })

        console.log(data)
    }

    async function onImport() {
        if (importRef?.current?.files?.length > 0) {
            console.log("Importing", importRef.current.files.length, "files")
            try {
                for (let i0 = 0; i0 < importRef.current.files.length; i0++) {
                    const importedFile = importRef.current.files[i0]
                    const results = []
                    if (importedFile) {
                        const parser = new XMLParser()
                        const buff = await importedFile.arrayBuffer()
                        const blob = new Blob([new Uint8Array(buff)], {type: importedFile.type})
                        const parsed = parser.parse(await blob.text())

                        // let's console log all debtors
                        try {
                            const entries = parsed.Document.BkToCstmrStmt.Stmt.Ntry

                            function parseSingleEntry(item) {
                                if (!item?.CdtDbtInd?.startsWith("CRDT")) return
                                results.push({
                                    /*amount: entry.Amt,
                                    creditorReference: entry.NtryDtls.TxDtls.RmtInf?.Strd?.CdtrRefInf?.Ref || null,
                                    debtor: {
                                        name: entry.NtryDtls.TxDtls.RltdPties.Dbtr.Nm,
                                        bankAccount: entry.NtryDtls.TxDtls.RltdPties.DbtrAcct.Id.IBAN
                                    },
                                    raw: entry,*/
                                    debtor: {
                                        name: item?.NtryDtls?.TxDtls?.RltdPties?.Dbtr?.Nm,
                                        bankId: item?.NtryDtls?.TxDtls?.RltdAgts?.DbtrAgt?.FinInstnId?.BIC || null,
                                        bankAccountNumber: item?.NtryDtls?.TxDtls?.RltdPties?.DbtrAcct?.Id?.IBAN,
                                        address1: item?.NtryDtls?.TxDtls?.RltdPties?.Dbtr?.PstlAdr?.AdrLine?.[0] || null,
                                        address2: item?.NtryDtls?.TxDtls?.RltdPties?.Dbtr?.PstlAdr?.AdrLine?.[1] || null,
                                        countryCode: item?.NtryDtls?.TxDtls?.RltdPties?.Dbtr?.PstlAdr?.Ctry || null,
                                    },
                                    creditor: {
                                        name: item?.NtryDtls?.TxDtls?.RltdPties?.Cdtr?.Nm,
                                        bankAccountNumber: item?.NtryDtls?.TxDtls?.RltdPties?.CdtrAcct?.Id?.IBAN || null,
                                        address1: item?.NtryDtls?.TxDtls?.RltdPties?.Cdtr?.PstlAdr?.AdrLine?.[0] || null,
                                        address2: item?.NtryDtls?.TxDtls?.RltdPties?.Cdtr?.PstlAdr?.AdrLine?.[1] || null,
                                        countryCode: item?.NtryDtls?.TxDtls?.RltdPties?.Cdtr?.PstlAdr?.Ctry || null
                                    },
                                    balanceIndicator: item?.CdtDbtInd === "CRDT" ? "CREDIT" : "DEBIT",
                                    creditorReference: item?.NtryDtls?.TxDtls?.RmtInf?.Strd?.CdtrRefInf?.Ref || null,
                                    amount: item?.Amt,
                                    bookingDate: new Date(item?.BookgDt?.Dt),
                                    rawTransactionDetails: JSON.stringify(item)
                                })
                            }

                            if(Array.isArray(entries)) {
                                console.log("Iterating through", entries.length, "entries")
                                for (let i = 0; i < entries.length; i++) {
                                    parseSingleEntry(entries[i])
                                }
                            } else if(entries) parseSingleEntry(entries)


                        } catch (e) {
                            console.log(e)
                        }
                    }
                    setResult(r => ({results: [...(r.results || []), ...results]}))
                }
            } catch (e) {
                console.error(e)
            }
            importRef.current.value = ""
        }
    }

    function goBack() {
        const link = document.createElement('a');
        link.href = "#/debtor-payments";
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
    }

    return (<>
        <div className="my-8 space-y-6">
            <form onSubmit={onSubmit} className="bg-white shadow sm:rounded-lg">
                <div>
                    <div className="px-4 py-5 sm:px-6 flex items-center">
                        <h3 className="text-lg leading-6 font-medium text-gray-900">Import payments XML</h3>
                        <label
                            className="ml-4 inline-flex items-center p-1 border border-transparent rounded-full shadow-sm text-gray-500 bg-gray-200 hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
                        >
                            <input ref={importRef} type="file" className="hidden"
                                   onChange={onImport}/>
                            <UploadIcon className="h-5 w-5" aria-hidden="true"/>
                        </label>
                    </div>
                    <DataTable isLoadingData={isSubmitting}
                               setSelectedItems={setSelectedItems}
                               selectedItems={selectedItems} result={result}/>
                </div>
                <div className="flex space-x-4 justify-end bg-gray-50 px-4 py-3 text-right sm:px-6">
                    <a
                        href="#/debtor-payments"
                        className="ml-3 bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
                    >
                        Cancel
                    </a>
                    <button
                        type="submit"
                        disabled={isSubmitting}
                        className={classNames(
                            "ml-3 inline-flex justify-center py-2 px-4 border text-sm font-medium rounded-md focus:outline-none",
                            !isSubmitting ? "text-white bg-gray-600 hover:bg-gray-700 focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 border-transparent shadow-sm" : "text-gray-300 bg-gray-50 border-gray-200 cursor-default"
                        )}
                    >
                        {isSubmitting ? "Saving..." : "Save"}
                        {isSubmitting && <Spinner button/>}
                    </button>
                </div>
            </form>
        </div>
    </>)
};
